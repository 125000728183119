import React, { Component } from 'react';
import './App.css';
import './assets/css/animation.css';

import Navbar from './components/Navbar.js';
import Intro from './components/Intro.js';
import Work from './components/Work.js';
// import Logos from './components/Logos.js';
import About from './components/About.js';
import OtherWork from './components/OtherWork.js';
import Contact from "./components/Contact.js";

import ReactGA from 'react-ga4';
ReactGA.initialize('G-471D3476N4')

class App extends Component {
	render() {
		return (
			<main className="bg-[--rsb-bg] text-[#fff]">
				<Navbar />
				<Intro />
				<Work />
				{/* <Logos /> */}
				<About />
				<OtherWork />
				<Contact />
			</main>
		);
	}
}

export default App;




