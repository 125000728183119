
import React, { Component } from 'react';
import Navlink from "./Navlink";
import '../assets/css/nav.css';


class Navbar extends Component {

	constructor(props) {
		super(props);
		this.state = { menuToggle: 'closed' };
		
		this.toggleNav = this.toggleNav.bind(this);
	}
	  
	toggleNav() {
		var navMobClasses = (this.state.menuToggle === "closed") ? "toggled-on" : "closed";
		this.setState({menuToggle: navMobClasses});
	}

	render() {
		return (
			<header className="site-header bg-[--rsb-bg] sticky top-0 z-[100]">
				<div className="container mx-auto flex flex-wrap p-5 px-8 flex-row items-center justify-between md:justify-center">
					<nav className="hidden md:flex md:mr-8 md:ml-auto md:py-1 md:pr-4 flex-wrap items-center text-base justify-end gap-10">
						<Navlink anchor="intro" text="Home"></Navlink>
						<Navlink anchor="work" text="Featured Work"></Navlink>
						<Navlink anchor="about" text="My Story"></Navlink>
					</nav>
					<div className="title-font font-medium text-white text-xl">
					<Navlink anchor="intro" text="EJ"></Navlink>
					</div>
					<nav className="hidden md:flex md:mr-auto md:ml-8 md:py-1 md:pl-4 flex-wrap items-center text-base justify-center gap-10">
						<Navlink anchor="past-work" text="Past Work"></Navlink>
						<Navlink anchor="contact" text="Contact"></Navlink>
						<a href="https://resabi.com" target="_blank" rel="noreferrer" title="Collection of my blogs and magazines">
							reSabi Media
						</a>
					</nav>
					<button className={`mobile-opener inline-flex md:hidden ${this.state.menuToggle}`}
					onClick={this.toggleNav.bind(this)}
					>
						<svg fill="none" xmlns="http://www.w3.org/2000/svg" width="28px" height="30px" viewBox="0 0 28 30">
							<rect x="0" y="6" width="30" fill="currentColor" height="2"></rect>
							<rect x="0" y="14" width="30" fill="currentColor" height="2"></rect>
							<rect x="0" y="22" width="30" fill="currentColor" height="2"></rect>
						</svg>
					</button>
					<nav id='mobile-menu' className={`md:hidden mobile-menu flex flex-col sticky top-20 z-[99] ${this.state.menuToggle}`}>
						<div className='py-8'>
							<Navlink anchor="intro" text="Home" toggleNav={this.toggleNav}></Navlink>
							<Navlink anchor="work" text="Featured Work" toggleNav={this.toggleNav}></Navlink>
							<Navlink anchor="about" text="My Story" toggleNav={this.toggleNav}></Navlink>
							<Navlink anchor="past-work" text="Past Work" toggleNav={this.toggleNav}></Navlink>
							<Navlink anchor="contact" text="Contact" toggleNav={this.toggleNav}></Navlink>		
							<a href="https://resabi.com" target="_blank" rel="noreferrer" title="Collection of my blogs and magazines">
								reSabi Media
							</a>
						</div>
					</nav>
				</div>
			</header>
		);
	}
}

export default Navbar;