
import { useInView } from "react-intersection-observer";

export default function BackgroundText(props) {
    const getMaxWidth = {
        maxWidth: props.maxWidth
    }

    const { ref, inView } = useInView({
        triggerOnce: false,
        rootMargin: "-100px 0px",
    });

    return(
        <span ref={ref} key={props.slug} className={`${inView ? "in-view" : "not-yet-loaded"} background-text text-5xl md:text-9xl uppercase font-black tracking-widest mx-auto ${props.addClass}`} style={getMaxWidth}>
            {props.content}
        </span>
    )
}