import React from "react";
import { useInView } from "react-intersection-observer";

export default function Intro(props) {

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: "-100px 0px",
      });
      
    return(
        <section id="intro" ref={ref} className={`${inView ? "in-view" : "not-yet-loaded"} container mx-auto flex px-5 pt-[20vh] flex-col items-center justify-center text-center text-white`}>

            <h1 className="text-3xl md:text-3xl mb-[4vh] font-serif">
                Howdy, I’m Eduard Jovanovic,
            </h1>
            <h2 className="text-3xl md:text-4xl mb-4">
                <strong>web developer</strong> specializing in WordPress, <br />
                <strong>designer</strong> of logos, identities and UI
            </h2>

            <a className="scroll-icon mt-[10vh]" href="#work"
            onClick={(e) => {
                e.preventDefault();
                const element = document.getElementById('work');
                element?.scrollIntoView({
                  behavior: 'smooth'
                }); 
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                <path fillRule="evenodd" d="M11.47 4.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1-1.06 1.06L12 6.31 8.78 9.53a.75.75 0 0 1-1.06-1.06l3.75-3.75Zm-3.75 9.75a.75.75 0 0 1 1.06 0L12 17.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-3.75 3.75a.75.75 0 0 1-1.06 0l-3.75-3.75a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                </svg>
            </a>
        </section>
    )
}