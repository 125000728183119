import React from "react";
import { useInView } from "react-intersection-observer";

export default function Project( props ) {    

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: "-50px 0px",
    });

    let renderImg1 = (props) => {
        if((typeof(props.img1) !== 'undefined' && props.img1 != null) ) {           
           return <img className="project-image-1" src={require(`../assets/img/projects/${props.img1}`)} alt={`Featured screenshot for ${props.title}`}></img>;
        }
    }

    let renderImg2 = (props) => {
        if((typeof(props.img2) !== 'undefined' && props.img2 != null) ) {
            if(props.slug === 'bgtm' ) {
                return <video className="project-image-2" controls autoPlay loop poster={require(`../assets/img/projects/${props.img2}`)}>
                    <source src={require(`../assets/img/projects/list-bgvp-video.mp4`)} type="video/mp4" ></source>
                    Your browser does not support the video tag.
                </video>
            } else {
                return <img className="project-image-2" src={require(`../assets/img/projects/${props.img2}`)} alt={`Additional screenshot for ${props.title}`}></img>;
            }
        }
    }

    return (        
        <div ref={ref} key={props.slug} className={`project ${props.slug} ${inView ? "in-view" : "not-yet-loaded"} fadeup flex flex-col lg:flex-row lg:justify-between gap-10 pb-14 md:pb-10 border-b-[1px] border-b-rsb-border`}>
            <div className="project-images flex flex-col sm:flex-row gap-10 items-start">
                { renderImg1(props) }
                { renderImg2(props) }
            </div>
            <div className="project-info text-left basis-1/4 flex flex-col gap-8">
                <h3 className="project-title">
                    {props.title}
                </h3>
                <div className="project-year project-meta">
                    <span className="project-meta-font pr-4">Year:</span>
                    <span className="project-meta-font flex">{props.year}</span>
                </div>
                <div className="project-role project-meta">
                    <span className="project-meta-font pr-4">Role:</span>
                    <span className="flex text-xs">{props.role}</span>
                </div>
                <div className="project-brief project-meta">
                    <span className="project-meta-font pr-4">Brief:</span>
                    <span className="flex text-xs">{props.brief}</span>
                </div>
            </div>
        </div>
    )
}